import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/OjjMLrhBkSk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p><em parentName="p">{`Direct link to this video: `}<a parentName="em" {...{
          "href": "https://youtu.be/OjjMLrhBkSk"
        }}>{`https://youtu.be/OjjMLrhBkSk`}</a></em></p>
    <p>{`This video is the second release in a project we have embarked on to document the impact of the products provided by our partners and the education and motivation provided by PayPerks for those products.`}</p>
    <p>{`In this case, we are focusing on the `}<a parentName="p" {...{
        "href": "https://www.usdirectexpress.com/"
      }}>{`Direct Express`}{`®`}</a>{` card, the mobile app for that card, and `}<a parentName="p" {...{
        "href": "https://www.prnewswire.com/news-releases/mastercard-and-comerica-team-up-with-new-york-city-startup-payperks-to-reward-direct-express-debit-mastercard-cardholders-for-learning-181236481.html"
      }}>{`PayPerks for Direct Express`}{`®`}</a>{`, which together helped Ray to fundamentally rethink how he manages his finances after an accident which took away his ability to work, both a professional drummer in a band and as a landscaper with his father.`}</p>
    <p>{`To continue exploring the impact of PayPerks, watch our first video with `}<a parentName="p" {...{
        "href": "https://blog.payperks.com/2019/04/25/payperks-directexpress-dawn-profile/"
      }}>{`Dawn`}</a>{`.`}</p>
    <p><em parentName="p">{`We will continue to post more videos like this as we speak to more PayPerks for Direct Express users. If you're a PayPerks user and you have a story you want us to tell, write PayPerks Help and let us know!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      